import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel} from './_models'
import * as authHelper from './AuthHelpers'
// import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import { postApi } from '../../../../axios'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: any
  setCurrentUser: Dispatch<SetStateAction<any>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<any>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  // const logout = () => {
  //   saveAuth(undefined)
  //   setCurrentUser(undefined)
  // }

const logout = async () => {
    try {
      const getAuthTokens = () => JSON.parse(localStorage.getItem("auth") || "null");
      const refreshToken = getAuthTokens()?.refresh;
      const user_role = getAuthTokens()?.role;
      if (refreshToken && user_role) {
        // Make an API call to renew tokens
        const response = await postApi(`logout/`, {
          refresh: refreshToken,
        });
  
        if (response.status === 200) {
          console.log("user  logout succesfully ", response.data);
        } else {
          console.error("Failed to logout ", response.data);
        }
      }
    } catch (error) {
      console.error("Error during logout token renewal:", error);
    } finally {
      saveAuth(undefined);
      setCurrentUser(undefined);
    }
  };
  

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()

  const [showSplashScreen, setShowSplashScreen] = useState(false)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    if (auth && auth.access) {
      setCurrentUser(auth?.role)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
