import axios from "axios";
 import {BASEURL} from './services'

// Set base URL for all requests
axios.defaults.baseURL = BASEURL;

/**
 * Create an Axios instance with default configuration
 */
const apiClient = axios.create({
  baseURL: BASEURL,
  headers: {
    "Content-Type": "application/json",
  },
});

/**
 * Utility function to get tokens from localStorage
 */
const getAuthTokens = () => JSON.parse(localStorage.getItem("auth") || "null");

/**
 * Utility function to check if access token is expired
 */
const isAccessTokenExpired = (token: string): boolean => {
  const expiryTime = JSON.parse(atob(token.split(".")[1])).exp * 1000;
  return Date.now() >= expiryTime;
};

/**
 * Add a request interceptor to attach the token to all requests
 */
apiClient.interceptors.request.use((config) => {
  const authToken = getAuthTokens()?.access;
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  return config;
});

/**
 * Add a response interceptor to handle 401 Unauthorized errors
 */
apiClient.interceptors.request.use(
  async (config) => {
    const authToken = getAuthTokens()?.access;

    if (authToken && isAccessTokenExpired(authToken)) {
      console.log("Access token expired. Refreshing...");
      const refreshToken = getAuthTokens()?.refresh;
      const user_role = getAuthTokens()?.role;

      if (!refreshToken) {
        console.error("No refresh token found.");
        return Promise.reject("No refresh token found");
      }

      try {
        const { data } = await axios.post(`${BASEURL}api/token/renew/`, {
          refresh: refreshToken,
          role: user_role,
        });
        localStorage.setItem("auth", JSON.stringify(data));
        config.headers.Authorization = `Bearer ${data.access}`;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error(
            "Error refreshing token:",
            error.response?.data || error.message
          );
        } else {
          console.error("Unexpected error:", error);
        }
        localStorage.clear();
        window.location.href = "/admin/login";
        return Promise.reject(error);
      }
    } else if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * GET request with token
 * @param {string} path - API endpoint
 * @returns {Promise} - Axios promise
 */
export const getApi = (path: string) => apiClient.get(path);

/**
 * POST request with data
 * @param {string} path - API endpoint
 * @param {object} data - Data to send
 * @returns {Promise} - Axios promise
 */
export const postApi = (path: string, data: object) => apiClient.post(path, data);

/**
 * POST request with file (multipart/form-data)
 * @param {string} path - API endpoint
 * @param {object} data - File data to send
 * @returns {Promise} - Axios promise
 */
export const postFileApi = (path: string, data: object) =>
  apiClient.post(path, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

/**
 * PUT request with data
 * @param {string} path - API endpoint
 * @param {object} data - Data to send
 * @returns {Promise} - Axios promise
 */
export const putApi = (path: string, data: object) => apiClient.put(path, data);

/**
 * PATCH request with data
 * @param {string} path - API endpoint
 * @param {object} data - Data to send
 * @returns {Promise} - Axios promise
 */
export const patchApi = (path: string, data: object) =>
  apiClient.patch(path, data);

/**
 * DELETE request with optional data
 * @param {string} path - API endpoint
 * @param {object} data - Optional data to send
 * @returns {Promise} - Axios promise
 */
export const deleteApi = (path: string, data: object) =>
  apiClient.delete(path, { data });

/**
 * POST request with custom token
 * @param {string} path - API endpoint
 * @param {object} data - Data to send
 * @param {string} token - Custom token
 * @returns {Promise} - Axios promise
 */
export const postWithCustomTokenApi = (
  path: string,
  data: object,
  token: string
) =>
  axios.post(path, data, {
    headers: {
      Authorization: token ? `Basic ${token}` : null,
      "Content-Type": "application/json",
    },
  });




















// import axios from 'axios'
// import {BASEURL} from './services'

// axios.defaults.baseURL = BASEURL

// /**
//  *
//  * @param {*} path  endpoint
//  * @returns data from api
//  */
// export const getApi = (path: string) => {
//   const authToken = JSON.parse(localStorage.getItem('auth') || '')?.access
//   const config = {
//     headers: {
//       Authorization: authToken ? `Bearer ${authToken}` : null,
//     },
//   }
//   return axios.get(path, config)
// }

// /**
//  *
//  * @param {*} path   endpoint
//  * @param {*} data object of data
//  * @returns   data from api
//  */
// export const postApi = (path: string, data: object) => {
//   const authToken = JSON.parse(localStorage.getItem('auth') || '')?.access
//   const config = {
//     headers: {
//       Authorization: authToken ? `Bearer ${authToken}` : null,
//     },
//   }
//   return axios.post(path, data, config)
// }

// /**
//  *
//  * @param {*} path   endpoint
//  * @param {*} data object of data
//  * @returns   data from api
//  */
// export function putApi(path: string, data: object) {
//   const authToken = JSON.parse(localStorage.getItem('auth') || '')?.access
//   const config = {
//     headers: {
//       Authorization: `Bearer ${authToken}`,
//     },
//   }

//   return axios.put(path, data, config)
// }

// /**
//  *
//  * @param {*} path   endpoint
//  * @param {*} data object of data
//  * @returns   data from api
//  */
// export function patchApi(path: string, data: object) {
//   const authToken = JSON.parse(localStorage.getItem('auth') || '')?.access
//   const config = {
//     headers: {
//       Authorization: `Bearer ${authToken}`,
//     },
//   }

//   return axios.patch(path, data, config)
// }

// /**
//  *
//  * @param {*} path   endpoint
//  * @param {*} data params
//  * @returns   data from api
//  */
// export function deleteApi(path: string, data: object) {
//   const authToken = JSON.parse(localStorage.getItem('auth') || '')?.access
//   const config = {
//     headers: {
//       Authorization: `Bearer ${authToken}`,
//     },
//   }

//   return axios.delete(path, {...config})
// }

